import React, { useEffect, Suspense, lazy } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Route, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { config } from '@fortawesome/fontawesome-svg-core';
import { GNVIEW_AUTH } from './constants/LocalStorage';
import { gnviewAuthUpdate } from './actions/GNViewAuthActions';
import { ROUTES } from './config/Routes';
import ErrorBoundary from './components/common/errorBoundary/ErrorBoundary';
import PrivateRoute from './components/common/privateRoute/privateRoute'; // Correct import

// https://stackoverflow.com/questions/48358529/failed-to-execute-removechild-on-node-with-fontawesome-in-react
config.autoReplaceSvg = 'nest';
library.add(fas);

// Lazy load components
const GNIDDistribution = lazy(() => import('./views/GNIDDistribution'));
const ImagesListView = lazy(() => import('./views/ImagesListView'));
const CatalogListView = lazy(() => import('./views/CatalogListView'));
const ImportStatusView = lazy(() => import('./views/ImportStatusView'));
const AccessDenied = lazy(() => import('./components/common/accessDenied/AccessDenied'));

const App = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const serializedAuthState = localStorage.getItem(GNVIEW_AUTH);
        const authState = JSON.parse(serializedAuthState);
        if (authState) {
            dispatch(gnviewAuthUpdate(authState));
        }
    }, [dispatch]);

    return (
        <ErrorBoundary>
            <Suspense fallback={<div>Loading...</div>}>
                <Switch>
                    <PrivateRoute exact path={ROUTES.GNID_DISTRIBUTION} component={GNIDDistribution} />
                    <PrivateRoute exact path={ROUTES.GNID_IMAGES} component={ImagesListView} />
                    <PrivateRoute exact path={ROUTES.GNID_CATALOGS} component={CatalogListView} />
                    <PrivateRoute exact path={ROUTES.GNID_IMPORT_STATUS} component={ImportStatusView} />
                    <Route path={ROUTES.ACCESS_DENIED} component={AccessDenied} />
                    {/* Add other routes here */}
                </Switch>
            </Suspense>
        </ErrorBoundary>
    );
};

App.propTypes = {
    location: PropTypes.object
};

export default withRouter(App);