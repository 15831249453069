import React, { useState, Suspense, lazy } from 'react';
import { Switch, Route, useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { ROUTES } from './config/Routes';
import ErrorBoundary from './components/errorBoundary/ErrorBoundary';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { config } from '@fortawesome/fontawesome-svg-core';
import { useEffect } from 'react';
import { GNVIEW_AUTH, GNVIEW_EMAIL } from './constants/LocalStorage';
import Cookies from 'js-cookie';

// Font Awesome settings
// https://stackoverflow.com/questions/48358529/failed-to-execute-removechild-on-node-with-fontawesome-in-react
config.autoReplaceSvg = "nest";
library.add(fas);

const Login = lazy(() => import('./components/login/Login'));
const Landing = lazy(() => import('./components/landing/Landing'));
const Logout = lazy(() => import('./components/logout/Logout'));
const TermsOfUse = lazy(() => import('./components/termsOfUse/TermsOfUse'));
const Forbidden = lazy(() => import('./components/forbidden/Forbidden'));
const Integration = lazy(() => import('./components/integration/Integration'));
const TrialForm = lazy(() => import('./components/trialForm/TrialForm'));
const ThankYou = lazy(() => import('./components/thankYou/ThankYou'));
const GradientMenu = lazy(() => import('./components/gradientMenu/GradientMenu'));
const PrivacyPolicy = lazy(() => import('./components/privacyPolicy/PrivacyPolicy'));

export default function App() {
    const location = useLocation();
    const history = useHistory();
    const serializedAuthState = localStorage.getItem(GNVIEW_AUTH);
    const email = Cookies.get(GNVIEW_EMAIL);
    const [showMenu, setShowMenu] = useState(false);

    // Any window.location.replace or location (react-router-dom) change we should re-evaluate whether the menu should be shown
    useEffect(() => {
        const shouldShow = serializedAuthState && (location.pathname !== ROUTES.REPORT_AN_ISSUE) && (location.pathname !== ROUTES.LOGOUT) && (location.pathname !== ROUTES.TRIAL_FORM) && (location.pathname !== ROUTES.THANK_YOU) && (location.pathname !== ROUTES.PRIVACY_POLICY);
        setShowMenu(shouldShow);
    }, [serializedAuthState, location.pathname]);

    useEffect(() => {
        let pathAfterLogin;
        const searchParams = queryString.parse(location.search);
        const redirectPath = searchParams.redirectPath;
        if (redirectPath && redirectPath?.length > 0) {
            pathAfterLogin = redirectPath;
        } else {
            const currentUrl = () => {
                if (location.search?.length > 0) {
                    return `${location.pathname}${location.search}`;
                } else {
                    return `${location.pathname}`;
                }
            };
            pathAfterLogin = currentUrl();
        }
        // Cookies have expired after 24 hrs, so JWT is also useless and will return a 401 at this point. Re-route the user to login again
        if (!email && ![ROUTES.LOGIN, ROUTES.TRIAL_FORM, ROUTES.THANK_YOU, ROUTES.TERMS_OF_USE, ROUTES.PRIVACY_POLICY].includes(location.pathname)) {
            console.warn("No Cookies are stored. Rerouting to login");
            localStorage.clear();
            history.push(`${ROUTES.LANDING}?redirectPath=${pathAfterLogin}`);
        }
    }, [email, history, location.pathname, location.search]);

    return (
        <div className="gnview-menu-container">
            {showMenu && (
                <Suspense fallback={<div>Loading...</div>}>
                    <GradientMenu />
                </Suspense>
            )}

            <div className='gnview-menu-inner-container'>
                <ErrorBoundary>
                    <div className="gnview-menu-main-container">
                        <Suspense fallback={<div>Loading...</div>}>
                            <Switch>
                                <Route exact key="login" path={ROUTES.LOGIN} component={Login} />
                                <Route exact key="landing" path={ROUTES.LANDING} component={Landing} />
                                <Route key="logout" path={ROUTES.LOGOUT} component={Logout} />
                                <Route key="termsOfUse" exact path={ROUTES.TERMS_OF_USE} component={TermsOfUse} />
                                <Route key="forbidden" exact path={ROUTES.AUTH_FORBIDDEN} component={Forbidden} />
                                <Route key="trialForm" exact path={ROUTES.TRIAL_FORM} component={TrialForm} />
                                <Route key="thankYou" exact path={ROUTES.THANK_YOU} component={ThankYou} />
                                <Route key="privacyPolicy" exact path={ROUTES.PRIVACY_POLICY} component={PrivacyPolicy} />
                            </Switch>
                        </Suspense>
                    </div>
                    <Suspense fallback={<div>Loading...</div>}>
                        <Integration />
                    </Suspense>
                </ErrorBoundary>
            </div>
        </div>
    );
}