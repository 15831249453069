import { ROUTES } from "../config/Routes";
import { ZENDESK_URL, ZENDESK_ISSUE_STATUS_URL } from "./DataAlerts";

export const MENU_CONTENTS = [
    {
        icon: 'rocket',
        title: 'Get Started',
        url: ROUTES.GET_STARTED,
        newTab: false
    },
    {
        icon: 'book',
        title: 'User Guide',
        url: 'http://www.gracenote.com/documentation/gn-view/html/Content/view/Overview.htm',
        newTab: true
    },
    {
        icon: 'bell',
        title: "What's New!"
    },
    {
        icon: 'triangle-exclamation',
        title: 'Report an Issue on Zendesk®',
        url: ZENDESK_URL,
        newTab: true
    },
    {
        icon: 'list-check',
        title: 'Issue Status',
        url: ZENDESK_ISSUE_STATUS_URL,
        newTab: true
    },
    {
        icon: 'message',
        title: 'Send Feedback',
        url: 'https://forms.gle/Ay7DDjz69kuWTmj87',
        newTab: true
    }
];